<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            type="button"
            @click="showModal({ type: 'cancel' })"
            class="btn btn-inverse"
            title="Kembali"
          >
            Kembali
          </button>
          <!-- <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button> -->
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs :tabIndex="1">
            <gtTab title="Detail Surat">
              <div class="row">
                <div class="col-md-6 pull-left">

                  <small class="text-muted">Tanggal Surat</small>
                  <h6>
                    {{
                      state.detail.tanggal_surat === ""
                        ? "-"
                        : formatDate(state.detail.tanggal_surat)
                    }}
                  </h6>
                  <small class="text-muted">Pengirim</small>
                  <h6>
                    {{
                      state.detail.senderUnit === ""
                        ? "-"
                        : state.detail.senderUnit
                    }}
                  </h6>
                  <small class="text-muted">Asal Surat</small>
                  <h6>
                    {{
                      state.detail.asalsurat === ""
                        ? "-"
                        : state.detail.asalsurat
                    }}
                  </h6>
                  <small class="text-muted">Perihal</small>
                  <h6>
                    {{
                      state.detail.subject === "" ? "-" : state.detail.subject
                    }}
                  </h6>
                  <small class="text-muted">Agenda</small>
                  <h6>
                    {{
                      state.detail.agenda_number === ""
                        ? "-"
                        : state.detail.agenda_number
                    }}
                  </h6>
                  <small class="text-muted"
                    >Jenis Naskah Dinas / Nama Naskah Dinas
                  </small>
                  <h6>
                    {{
                      state.detail.document_jenis == "" ||
                      state.detail.document_jenis == null ||
                      state.detail.document_jenis == undefined
                        ? "-"
                        : state.detail.document_jenis.text
                    }}
                  </h6>
                  <small class="text-muted">Sifat Surat</small>
                  <h6>
                    {{ state.detail.sifat === "" ? "-" : state.detail.sifat }}
                  </h6>
                  <small class="text-muted">Kecepatan Surat</small>
                  <h6>
                    {{
                      state.detail.document_kecrat == "" ||
                      state.detail.document_kecrat == null ||
                      state.detail.document_kecrat == undefined
                        ? "-"
                        : state.detail.document_kecrat.text
                    }}
                  </h6>
                </div>

                <div class="col-md-6 pull-left">
                  <small class="text-muted">Ringkasan Surat</small>
                  <h6>
                    <span
                      v-html="
                        state.detail.description === ''
                          ? '-'
                          : state.detail.description
                      "
                    ></span>
                  </h6>
                  <small class="text-muted">Nomor Dokumen</small>
                  <h6>
                    {{
                      state.detail.nomor_surat === ""
                        ? "-"
                        : state.detail.nomor_surat
                    }}
                  </h6>
                  <small class="text-muted">Halaman / Lampiran</small>
                  <h6>
                    {{ state.detail.pages === "" ? "-" : state.detail.pages
                    }}{{ " / " }}
                    {{ state.detail.jumlah === "" ? "-" : state.detail.jumlah }}
                    {{
                      state.detail.document_lampiran === ""
                        ? "-"
                        : state.detail.document_lampiran.text
                    }}
                  </h6>
                  <small class="text-muted">Kepada</small>
                  <h6 v-if="state.detail.receiver === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                  <small class="text-muted">Tembusan</small>
                  <h6 v-if="state.detail.receiver2 === ''">-</h6>
                  <h6 v-else>
                    <table>
                      <tr
                        v-for="(rec, index) in state.detail.receiver2"
                        :key="rec.value"
                      >
                        <td>{{ index + 1 + ". " + rec.text }}</td>
                        <td style="margin-left: 10px">
                          <i
                            v-if="rec.approval == 2"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: blue;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 1"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: green;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                          <i
                            v-else-if="rec.approval == 3"
                            class="fa fa-envelope"
                            style="
                              cursor: pointer;
                              background: red;
                              padding: 4px;
                              color: #fff;
                              border-radius: 5px;
                            "
                            @click="showModal(rec)"
                          ></i>
                        </td>
                      </tr>
                    </table>
                  </h6>
                </div>
              </div>
            </gtTab>

            <gtTab title="File Terlampir">
              <div class="row">
                <div class="col-md-6 pull-left">
                  <small class="text-muted">Tanggal Surat</small>
                  <h6>
                    {{
                      state.detail.tanggal_surat === ""
                        ? "-"
                        : formatDate(state.detail.tanggal_surat)
                    }}
                  </h6>
                  <small class="text-muted">Pengirim</small>
                  <h6>
                    {{
                      state.detail.senderUnit === ""
                        ? "-"
                        : state.detail.senderUnit
                    }}
                  </h6>
                  <small class="text-muted">Asal Surat</small>
                  <h6>
                    {{
                      state.detail.asalsurat === ""
                        ? "-"
                        : state.detail.asalsurat
                    }}
                  </h6>
                  <!-- <h6>{{ (state.detail.document_asrat.id == "" || state.detail.document_asrat==null || state.detail.document_asrat == undefined) ? '-' : state.detail.document_asrat.id }}</h6> -->
                  <small class="text-muted">Perihal</small>
                  <h6>
                    {{
                      state.detail.subject === "" ? "-" : state.detail.subject
                    }}
                  </h6>
                </div>

                <div class="col-md-6 pull-left">
                  <small class="text-muted">Ringkasan Surat</small>
                  <h6><span v-html="state.detail.description"></span></h6>
                  <small class="text-muted">Nomor Dokumen</small>
                  <h6>
                    {{
                      state.detail.nomor_surat === ""
                        ? "-"
                        : state.detail.nomor_surat
                    }}
                  </h6>
                  <small class="text-muted">Agenda</small>
                  <h6>
                    {{
                      state.detail.agenda_number === ""
                        ? "-"
                        : state.detail.agenda_number
                    }}
                  </h6>
                </div>
              </div>
              <div class="col-md-12">
                <small class="text-muted">File</small>
                <br />
                <table class="table -table-striped">
                  <tr>
                    <td>Nama File</td>
                    <td>Nama Pengunggah</td>
                    <td>Tanggal Diunggah</td>
                    <td>Buka File</td>
                  </tr>
                  <tr v-if="!state.detail.files.length">
                    <td colspan="4">File tidak ada</td>
                  </tr>
                  <tr
                    v-for="file in state.detail.files"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td>{{ state.detail.document_asrat.text }}</td>
                    <td>{{ formatDate(state.detail.tanggal_surat) }}</td>
                    <td>
                      <!-- <a
                        @click="downloadFileForce(file.url)"
                        class="btn btn-success text-white"
                        title="Download"
                        ><i class="fa fa-download text-white"></i> </a
                      >&nbsp; -->
                      <a
                        class="btn btn-info text-white"
                        @click="downloadFileForce(file.url)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>

            <!-- <gtTab title="Tracking">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <TreeView class="item" :model="treeData" />
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>

            <gtTab title="History">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h5>
                        <ol>
                          <li>
                            <span
                              v-if="state.detail.history == undefined"
                            ></span>
                            <span
                              v-else
                              v-html="state.detail.history.document"
                            ></span>
                          </li>
                          <span v-if="state.detail.history == undefined"></span>
                          <li
                            v-else-if="
                              state.detail.history.konfirmasi != undefined &&
                              state.detail.history.konfirmasi != ''
                            "
                          >
                            <span
                              v-html="state.detail.history.konfirmasi"
                            ></span>
                          </li>
                          <span v-if="state.detail.history == undefined"></span>
                          <li
                            v-else-if="
                              state.detail.history.disposition != undefined &&
                              state.detail.history.disposition != ''
                            "
                          >
                            <span
                              v-html="state.detail.history.disposition"
                            ></span>
                          </li>
                        </ol>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </gtTab> -->

          </gtTabs>
        </div>
      </div>

      <ModalDefault
        v-show="isVisible"
        :title="modalTitle"
        :content="modalContent"
        :action="ActionChangeable"
        :confirmation="isConfirmation"
        @close="onClose"
      />
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "../../../components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },

  data() {
    return {
      headers: {
        nama_file: {
          label: "Nama File",
          sortable: false,
        },
        nama_pengunggah: {
          label: "Nama Pengunggah",
          sortable: false,
        },
        tanggal_upload: {
          label: "Tanggal Upload",
          sortable: false,
        },
      },
      myHTML: "",
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
    };
  },

  computed: {
    treeData() {
      return this.$store.state.documentInRejected.detail.tracking !== undefined
        ? this.$store.state.documentInRejected.detail.tracking
        : {};
    },
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.documentInRejected;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_surat);
    },
    agendanumber() {
      return this.state.detail.agenda_number;
    },
    nomorsurat() {
      return this.state.detail.nomorsurat;
    },
    subject() {
      return this.state.detail.subject;
    },
    sifatsurat() {
      return this.state.detail.document_sifat.text;
    },
    asalsurat() {
      return this.state.detail.sender.name;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("documentInRejected/STATE", state);
      this.$store.dispatch(
        "documentInRejected/getDocumentInRejectedById",
        this.$route.params
      );
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    goBack() {
      this.$store.dispatch("documentInRejected/onCancel");
    },
    approve(val) {
      let payload = {
        document: this.$route.params.id,
        approval: val,
        message: this.myHTML,
      };
      this.$store.dispatch(
        "documentInRejected/approveDoc",
        JSON.stringify(payload)
      );
    },
    goSend() {
      let payload = {
        document: this.$route.params.id,
      };
      this.$store.dispatch(
        "documentInRejected/goSend",
        JSON.stringify(payload)
      );
    },
  },
};
</script>
